import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { API_URL } from './Config';
import { useNavigate } from 'react-router-dom';

export function Header()
{
    // const [schoolLogo, setSchoolLogo] = useState('');
    const [SchoolData, setSchoolData] = useState('');
    const [truncatedSchoolName, setTruncatedSchoolName] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();

    const truncateText = (text, maxLength) => {
        if(text.length <= maxLength)
        {
            return text;
        }
        
        return text.slice(0, maxLength) + '...';
    };

    const RedirectToMyKids = () => {
        navigate("/ViewAllChildrens");
    }

    useEffect(() => {
        const getSchoolData = async () => {
            try {
                const mobileNumber = localStorage.getItem('Mobile');

                const response = await axios.post(`${API_URL}/ParentsAPI.php`, {
                    aksi: 'Parents_Childrens',
                    MobileNumber: mobileNumber
                });

                if (response.data.success && response.data.result.length > 0)
                {
                    const name = response.data.result[0].school_name;

                    setSchoolData(response.data.result[0]);

                    setTruncatedSchoolName(truncateText(name, 21));
                }
            }
            catch(error)
            {
                console.error("Error", error);
            }
        };

        getSchoolData();

        const intervalId = setInterval(getSchoolData, 5000);
        return () => clearInterval(intervalId);

    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if(dropdownRef.current && !dropdownRef.current.contains(event.target))
            {
                setShowDropdown(false);
            }
        };

        if(showDropdown)
        {
            document.addEventListener('click', handleClickOutside);
        }
        else
        {
            document.removeEventListener('click', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };

    }, [showDropdown]);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    return (
        <>
            <nav className="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600 shadow-md">
                <div className="max-w-screen-xl mx-auto p-4 flex items-center justify-between relative"> {/* Added relative positioning */}
                    <div className="flex items-center">
                        {SchoolData.school_logo && (
                            <div className="w-8 h-8 rounded-full overflow-hidden">
                                <img src={SchoolData.school_logo} className="w-full h-full object-cover" alt="School Logo" />
                            </div>
                        )}
                    </div>
                    <div className="flex-grow text-center px-4">
                        <span className="text-lg font-semibold text-gray-700 dark:text-white">
                            {truncatedSchoolName}
                        </span>
                    </div>
                    <div className="flex items-center relative" ref={dropdownRef}> {/* Added ref to dropdown container */}
                        <button type="button" className="relative" onClick={toggleDropdown}>
                            <svg className="w-6 h-6 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 2a6 6 0 00-6 6v2a6.002 6.002 0 01-4 5.659V17a2 2 0 002 2h16a2 2 0 002-2v-1.341A6.002 6.002 0 0116 10V8a6 6 0 00-6-6zM3 17h14v-1.341A8.01 8.01 0 0010 18a8.01 8.01 0 00-7-2.341V17z" />
                            </svg>
                            <span className="absolute top-0 right-0 w-3.5 h-3.5 bg-red-500 border-2 border-white dark:border-gray-900 rounded-full"></span>
                        </button>
                        {showDropdown && (
                            <div className="absolute right-0 mt-32 mr-7 border border-black w-48 bg-white rounded-md overflow-hidden shadow-xl z-10"> {/* Adjusted position and added z-index */}
                                <div className="py-1">
                                    <span onClick={ RedirectToMyKids } className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer">My Kids</span>
                                    <span className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer">More</span>
                                    <span className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer">About</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </nav>
        </>
    );
}