import React, { useEffect, useState } from 'react';
import { BottomNavigation } from './BottomNavigation';
import { Header } from './Header';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from './Config';

export function ViewChildrenFee() {
    const [details, setDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [feeData, setFeeData] = useState([]);
    const location = useLocation();
    const { childrenId } = location.state || {};

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(`${API_URL}/ParentsAPI.php`, {
                    aksi: 'Childrens_Details',
                    ChildrenId: childrenId
                });
                
                if (response.data.success)
                {
                    setDetails(response.data.result[0]);
                }
                else
                {
                    console.error('Error fetching details:', response.data.error);
                }
            }
            catch(error)
            {
                console.error('Error fetching details:', error);
            }
            finally
            {
                setLoading(false);
            }
        };

        const getChildrenFee = async () => {
            try
            {
                const response = await axios.post(`${API_URL}/ParentsAPI.php`, {
                    aksi: 'Childrens_Fee_Details',
                    ChildrenId: childrenId
                });

                if(response.data.success)
                {
                    setFeeData(response.data.result);
                }
                else
                {
                    console.log('There Is A Error In API');
                }
            }
            catch(error)
            {
                console.log("Error", error);
            }
            finally
            {
                setLoading(false);
            }
        };

        fetchData();
        getChildrenFee();

        const intervalId = setInterval(() => {
            getChildrenFee();
            fetchData();
        }, 5000);

        return () => clearInterval(intervalId);

    }, [childrenId]);
    
    if(loading)
    {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
            </div>
        );
    }
    
    return (
        <>
            <Header />
            <div className="text-center items-center justify-center mt-20 px-2">
                <div className="mb-3 max-w-md mx-auto">
                    <h1 className="text-5xl font-bold text-blue-500" style={{ fontFamily: 'Varela Round' }}>
                        View Fee
                    </h1>
                </div>
                <div className="overflow-x-auto shadow-md rounded-lg max-w-md mx-auto">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-collapse">
                        <thead className="text-xs text-white uppercase bg-black">
                            <tr>
                                <th scope="col" className="px-6 py-3 border border-gray-300">
                                    Sr. No.
                                </th>
                                <th scope="col" className="px-6 py-3 border border-gray-300">
                                    Children Name
                                </th>
                                <th scope="col" className="px-6 py-3 border border-gray-300">
                                    Month
                                </th>
                                <th scope="col" className="px-6 py-3 border border-gray-300">
                                    Year
                                </th>
                                <th scope="col" className="px-6 py-3 border border-gray-300">
                                    Date
                                </th>
                                <th scope="col" className="px-6 py-3 border border-gray-300">
                                    Date & Time
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {feeData.length > 0 ? (
                                feeData.map((item, index) => (
                                    <tr key={index} className="bg-white border-b border-gray-200">
                                        <td className="px-6 py-4 border border-gray-300">
                                            { index + 1 }
                                        </td>
                                        <td className="px-6 py-4 border border-gray-300">
                                            { details ? details.student_name : 'Loading...' }
                                        </td>
                                        <td className="px-6 py-4 border border-gray-300">
                                            { item.month }
                                        </td>
                                        <td className="px-6 py-4 border border-gray-300">
                                            { item.year }
                                        </td>
                                        <td className="px-6 py-4 border border-gray-300">
                                            { item.date }
                                        </td>
                                        <td className="px-6 py-4 border border-gray-300">
                                            { item.date_time }
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6" className="px-6 py-4 border border-gray-300 text-center">
                                        No data available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            <BottomNavigation />
        </>
    );
}