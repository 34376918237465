import { API_URL } from './Config';
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

export function Login() {
    const navigate = useNavigate();
    const [schoolCode, setSchoolCode] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [password, setPassword] = useState("");
    
    const loginUser = async (e) => {
        e.preventDefault();
        
        if(!schoolCode || !mobileNumber || !password) {
            toast.error('Please fill all the fields.');
            return;
        }
        
        try {
            const response = await axios.post(`${API_URL}/ParentsAPI.php`, {
                aksi: 'Login_Parents',
                SchoolCode: schoolCode,
                MobileNumber: mobileNumber,
                Password: password,
            });

            if(response.data.success) {
                const loginDetails = JSON.stringify(response.data);

                localStorage.setItem("loginStatus", true);
                localStorage.setItem("loginDetails", loginDetails);
                localStorage.setItem("SchoolCode", schoolCode);
                localStorage.setItem("Mobile", mobileNumber);
                localStorage.setItem("Password", password);

                toast.success('Successfully logged in!');
                setTimeout(() => {
                    navigate("/");
                }, 2000);
            } else {
                toast.error('Failed to login. Please check your credentials.');
            }
        } catch(error) {
            console.error('Error logging in:', error);
            toast.error('Failed to login. Please try again later.');
        }
    }

    return (
        <>
            <ToastContainer position="top-left" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className=" max-w-md mx-auto min-h-screen flex items-center justify-center bg-cover bg-center" style={{ backgroundImage: 'url("/icons/login_bg.png")' }}>
                <motion.div className="w-full" initial={{ opacity: 0, scale: 0.9 }} animate={{ opacity: 1, scale: 1 }} transition={{ duration: 0.5 }}>
                    <section className="w-full dark:bg-gray-800 flex items-center justify-center">
                        <div className="w-full px-6 py-8">
                            <div className="flex flex-col items-center justify-center">
                                <span className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                                    <img className="w-8 h-8 mr-2" src="https://www.svgrepo.com/show/343535/youtube-music-song-multimedia-audio.svg" alt="logo" />
                                    YouTube
                                </span>
                                <div className="w-full bg-white rounded-lg shadow-xl dark:border dark:bg-gray-800 dark:border-gray-700">
                                    <div className="p-6 space-y-4">
                                        <h1 style={{ fontFamily: 'Varela Round' }} className="text-center items-center justify-center text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                            Sign In
                                        </h1>
                                        <form className="space-y-4" onSubmit={loginUser}>
                                            <div className="relative z-0 w-full mb-5 group">
                                                <input onChange={ (e)=>setSchoolCode(e.target.value) } type="text" name="floating_first_name" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                                                <label className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Enter School Code</label>
                                            </div>
                                            <div className="relative z-0 w-full mb-5 group">
                                                <input onChange={ (e)=>setMobileNumber(e.target.value) } type="text" name="floating_last_name" id="LastNameInput" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                                                <label className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Enter Mobile Number</label>
                                            </div>
                                            <div className="relative z-0 w-full mb-5 group">
                                                <input onChange={ (e)=>setPassword(e.target.value) } type="text" name="floating_last_name" id="LastNameInput" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                                                <label className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Enter Password</label>
                                            </div>
                                            <button
                                                type="submit"
                                                className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                                            >
                                                Sign in
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </motion.div>
            </div>
        </>
    );
}