import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from './Config';
import { BottomNavigation } from "./BottomNavigation";
import { Header } from "./Header";
import { useNavigate } from "react-router-dom";

export function ViewAllChildrens() {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getData = async () => {
        try {
            const mobileNumber = localStorage.getItem('Mobile');
            const response = await axios.post(`${API_URL}/ParentsAPI.php`, {
                aksi: 'Parents_Childrens',
                MobileNumber: mobileNumber
            });

            if (response.data.success) {
                setData(response.data.result);
            } else {
                console.error('Error fetching data:', response.data.error);
            }
        } catch (error) {
            console.error("Error", error);
        }
        finally
        {
            setLoading(false);
        }
    };

    const viewChildrenDetails = (childrenId) => {
        navigate(`/ViewChildrenDetails/${childrenId}`);
    };

    const viewChildrenFee = (childrenId) => {
        navigate('/ViewChildrenFee', { state: { childrenId } });
    };

    useEffect(() => {
        getData();
        
        const intervalId = setInterval(getData, 5000);
        return () => clearInterval(intervalId);
    }, []);
    
    if(loading)
        {
            return (
                <div className="flex items-center justify-center h-screen">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
                </div>
            );
        }
        
    return (
        <>
            <Header />
            <BottomNavigation />
            <div className="text-center items-center justify-center mt-20 px-2">
                <div className="mb-3 max-w-md mx-auto">
                    <h1 className="text-5xl font-bold text-blue-500" style={{ fontFamily: 'Varela Round' }}>
                        My Kids
                    </h1>
                </div>
                <div className="overflow-x-auto shadow-md rounded-lg max-w-md mx-auto">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-collapse">
                        <thead className="text-xs text-white uppercase bg-black">
                            <tr>
                                <th scope="col" className="px-6 py-3 border border-gray-300">
                                    Sr. No.
                                </th>
                                <th scope="col" className="px-6 py-3 border border-gray-300">
                                    Student Name
                                </th>
                                <th scope="col" className="px-6 py-3 border border-gray-300">
                                    Father Name
                                </th>
                                <th scope="col" className="px-6 py-3 border border-gray-300">
                                    Mother Name
                                </th>
                                <th scope="col" colSpan="2" className="px-6 py-3 border border-gray-300 text-center">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.length > 0 ? (
                                data.map((item, index) => (
                                    <tr key={index} className="bg-white border-b border-gray-200">
                                        <td className="px-6 py-4 border border-gray-300">
                                            {index + 1}
                                        </td>
                                        <td className="px-6 py-4 border border-gray-300">
                                            {item.student_name}
                                        </td>
                                        <td className="px-6 py-4 border border-gray-300">
                                            {item.father_name}
                                        </td>
                                        <td className="px-6 py-4 border border-gray-300">
                                            {item.mother_name}
                                        </td>
                                        <td className="px-6 py-4 border border-gray-300 text-center">
                                            <button onClick={() => viewChildrenDetails(item.id)} type="button" className="rounded-full text-white bg-green-500 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium text-sm px-3.5 py-2.5">
                                                <i className="fa fa-eye" />
                                            </button>
                                        </td>
                                        <td className="px-6 py-4 border border-gray-300 text-center">
                                            <button onClick={() => viewChildrenFee(item.id)} type="button" className="rounded-full text-white bg-purple-400 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium text-sm px-3.5 py-2.5">
                                                <i className="fa fa-money" />
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6" className="px-6 py-4 border border-gray-300 text-center">
                                        No data available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}