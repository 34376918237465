import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from './Config';
import { BottomNavigation } from './BottomNavigation';
import { Header } from './Header';
import { useParams } from 'react-router-dom';

export function ViewChildrenDetails() {
    const { childrenId } = useParams();
    const [details, setDetails] = useState(null);
    const [activeTab, setActiveTab] = useState('All');
    const [attendanceDetails, setAttendanceDetails] = useState({
        present: [],
        absent: [],
        leave: [],
        sunday: [],
        holiday: [],
        all: []
    });
    const [loading, setLoading] = useState(true);
    const [totalCounts, setTotalCounts] = useState({
        present: 0,
        absent: 0,
        leave: 0,
        sunday: 0,
        holiday: 0
    });
    const [dateOfMonthList, setDateOfMonthList] = useState([]);

    useEffect(() => {
        const fetchAttendance = async (status) => {
            setLoading(true);
            try {
                const response = await axios.post(`${API_URL}/ParentsAPI.php`, {
                    aksi: 'Children_Attendance',
                    ChildrenId: childrenId,
                    AttendanceStatus: status
                });
                if (response.data.success) {
                    const { result } = response.data;

                    if (result.length > 0) {
                        const attendanceData = result[0].attendance_details;
                        const present = [];
                        const absent = [];
                        const leave = [];
                        const sunday = [];
                        const holiday = [];
                        const all = [];

                        attendanceData.forEach(data => {
                            const item = {
                                attendence_date: data.attendence_date,
                                status_of_attendance: data.status_of_attendance
                            };

                            switch (data.status_of_attendance) {
                                case 'P':
                                    present.push(item);
                                    break;
                                case 'A':
                                    absent.push(item);
                                    break;
                                case 'L':
                                    leave.push(item);
                                    break;
                                case 'sun':
                                    sunday.push(item);
                                    break;
                                case 'OFF':
                                    holiday.push(item);
                                    break;
                                default:
                                    break;
                            }
                            all.push(item);
                        });

                        setAttendanceDetails({ present, absent, leave, sunday, holiday, all });

                        setTotalCounts({
                            present: response.data.present_count,
                            absent: response.data.absent_count,
                            leave: response.data.leave_count,
                            sunday: response.data.sunday_count,
                            holiday: response.data.holiday_count
                        });
                        
                        const dates = response.data.attendance_dates.map(item => item.date_of_month);
                        setDateOfMonthList(dates);
                    }
                } else {
                    console.log(`Error fetching ${status} attendance:`, response.data.error);
                }
            } catch (error) {
                console.error(`Error fetching ${status} attendance:`, error);
            } finally {
                setLoading(false);
            }
        };

        const fetchData = async () => {
            try {
                const response = await axios.post(`${API_URL}/ParentsAPI.php`, {
                    aksi: 'Childrens_Details',
                    ChildrenId: childrenId
                });

                if (response.data.success) {
                    setDetails(response.data.result[0]);
                } else {
                    console.error('Error fetching details:', response.data.error);
                }
            } catch (error) {
                console.error('Error fetching details:', error);
            }
            finally
            {
                setLoading(false);
            }
        };

        fetchData();
        fetchAttendance(getAttendanceStatus(activeTab));
    }, [childrenId, activeTab]);

    const getAttendanceStatus = (tab) => {
        switch (tab) {
            case 'All':
                return '';
            case 'Present':
                return 'P';
            case 'Absent':
                return 'A';
            case 'Leave':
                return 'L';
            case 'Sunday':
                return 'sun';
            case 'Holiday':
                return 'OFF';
            default:
                return '';
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'P':
                return 'bg-green-500';
            case 'A':
                return 'bg-red-500';
            case 'L':
                return 'bg-blue-500';
            case 'sun':
                return 'bg-blue-900 text-white';
            case 'OFF':
                return 'bg-red-700 text-white';
            default:
                return '';
        }
    };

    const renderTabContent = () => {
        let data;
        switch (activeTab) {
            case 'All':
                data = attendanceDetails.all;
                break;
            case 'Present':
            case 'Absent':
            case 'Leave':
            case 'Sunday':
            case 'Holiday':
                data = attendanceDetails[activeTab.toLowerCase()];
                break;
            default:
                data = [];
                break;
        }
        
        if (loading) {
            return (
                <div className="flex items-center justify-center h-full">
                    <span className="text-lg font-medium text-gray-500 ml-2 mb-5">Loading...</span>
                </div>
            );
        }

        if (!data || data.length === 0) {
            return (
                <div className="flex items-center justify-center h-full">
                    <span className="text-lg font-medium text-gray-500 ml-2 mb-5">No records found</span>
                </div>
            );
        }

        const getDateStatus = (date) => {
            const attendanceItem = data.find(item => item.attendence_date === date);
            return attendanceItem ? attendanceItem.status_of_attendance : '';
        };

        return (
            <div className="w-full px-2">
                <h3 className="font-medium text-gray-900 text-left">
                    {activeTab} Attendance
                </h3>
                <div className="mt-5 w-full flex-col items-center overflow-hidden text-sm grid grid-cols-7 gap-1">
                    {dateOfMonthList.map(date => {
                        const status = getDateStatus(date);

                        let statusLetter = '';

                        switch (status)
                        {
                            case 'P':
                                statusLetter = 'P';
                                break;
                            case 'A':
                                statusLetter = 'A';
                                break;
                            case 'L':
                                statusLetter = 'L';
                                break;
                            case 'sun':
                                statusLetter = 'S';
                                break;
                            case 'OFF':
                                statusLetter = 'H';
                                break;
                            default:
                                statusLetter = '';
                                break;
                        }
                        return (
                            <div key={date} className={`py-2 border border-black rounded-lg items-center justify-center text-center ${getStatusColor(status)} transition duration-300 ease-in-out`}>
                                <span className="block text-xl font-semibold">{ statusLetter || date }</span>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    if (!details) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    return (
        <>
            <Header />
            <div className="antialiased mb-20 mt-40 mx-2">
                <div className="container">
                    <div className="bg-gray-50 relative shadow-lg rounded-lg max-w-md mx-auto">
                        <div className="flex justify-center">
                            <img src={details.student_image || 'https://via.placeholder.com/150'} alt="avatar" className="rounded-full mx-auto absolute -top-20 w-32 h-32 shadow-md border-4 border-white transition duration-200 transform hover:scale-110" />
                        </div>
                        <div className="mt-16">
                            <h1 className="mb-5 font-bold text-center text-3xl text-gray-900">
                                {details.student_name}
                            </h1>
                            <div className="bg-white shadow-lg rounded-lg m-1 border border-gray-300">
                                <div className="grid grid-cols-5 gap-2 m-2 border-gray-400 rounded-lg py-2 bg-blue-200">
                                    <div className="flex flex-col items-center justify-center text-center relative">
                                        <p className="font-semibold" style={{ fontFamily: 'Varela Round', fontSize: '15px' }}>
                                            Present
                                        </p>
                                        <p className="font-semibold" style={{ fontFamily: 'Varela Round', fontSize: '15px' }}>
                                            {totalCounts.present}
                                        </p>
                                        <div className="absolute right-0 top-0 h-full w-0.5 bg-orange-400"></div>
                                    </div>
                                    <div className="flex flex-col items-center justify-center text-center relative">
                                        <p className="font-semibold" style={{ fontFamily: 'Varela Round', fontSize: '15px' }}>
                                            Absent
                                        </p>
                                        <p className="font-semibold" style={{ fontFamily: 'Varela Round', fontSize: '15px' }}>
                                            {totalCounts.absent}
                                        </p>
                                        <div className="absolute right-0 top-0 h-full w-0.5 bg-orange-400"></div>
                                    </div>
                                    <div className="flex flex-col items-center justify-center text-center relative">
                                        <p className="font-semibold" style={{ fontFamily: 'Varela Round', fontSize: '15px' }}>
                                            Leave
                                        </p>
                                        <p className="font-semibold" style={{ fontFamily: 'Varela Round', fontSize: '15px' }}>
                                            {totalCounts.leave}
                                        </p>
                                        <div className="absolute right-0 top-0 h-full w-0.5 bg-orange-400"></div>
                                    </div>
                                    <div className="flex flex-col items-center justify-center text-center relative">
                                        <p className="font-semibold" style={{ fontFamily: 'Varela Round', fontSize: '15px' }}>
                                            Sunday
                                        </p>
                                        <p className="font-semibold" style={{ fontFamily: 'Varela Round', fontSize: '15px' }}>
                                            {totalCounts.sunday}
                                        </p>
                                        <div className="absolute right-0 top-0 h-full w-0.5 bg-orange-400"></div>
                                    </div>
                                    <div className="flex flex-col items-center justify-center text-center">
                                        <p className="font-semibold" style={{ fontFamily: 'Varela Round', fontSize: '15px' }}>
                                            Holiday
                                        </p>
                                        <p className="font-semibold" style={{ fontFamily: 'Varela Round', fontSize: '15px' }}>
                                            {totalCounts.holiday}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full mt-5">
                                <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
                                    <ul className="flex flex-wrap justify-center -mb-px text-sm font-medium text-center" role="tablist">
                                        <li className="me-2" role="presentation">
                                            <button className={`inline-block p-4 border-b-2 rounded-t-lg ${activeTab === 'All' ? 'border-purple-600 text-purple-600' : 'hover:text-gray-600 hover:border-gray-300'}`} onClick={() => setActiveTab('All')} type="button" role="tab">
                                                All
                                            </button>
                                        </li>
                                        <li className="me-2" role="presentation">
                                            <button className={`inline-block p-4 border-b-2 rounded-t-lg ${activeTab === 'Present' ? 'border-purple-600 text-purple-600' : 'hover:text-gray-600 hover:border-gray-300'}`} onClick={() => setActiveTab('Present')} type="button" role="tab">
                                                Present
                                            </button>
                                        </li>
                                        <li className="me-2" role="presentation">
                                            <button className={`inline-block p-4 border-b-2 rounded-t-lg ${activeTab === 'Absent' ? 'border-purple-600 text-purple-600' : 'hover:text-gray-600 hover:border-gray-300'}`} onClick={() => setActiveTab('Absent')} type="button" role="tab">
                                                Absent
                                            </button>
                                        </li>
                                        <li className="me-2" role="presentation">
                                            <button className={`inline-block p-4 border-b-2 rounded-t-lg ${activeTab === 'Leave' ? 'border-purple-600 text-purple-600' : 'hover:text-gray-600 hover:border-gray-300'}`} onClick={() => setActiveTab('Leave')} type="button" role="tab">
                                                Leave
                                            </button>
                                        </li>
                                        {/* <li className="me-2" role="presentation">
                                            <button className={`inline-block p-4 border-b-2 rounded-t-lg ${activeTab === 'Sunday' ? 'border-purple-600 text-purple-600' : 'hover:text-gray-600 hover:border-gray-300'}`} onClick={() => setActiveTab('Sunday')} type="button" role="tab">
                                                Sun
                                            </button>
                                        </li>
                                        <li role="presentation">
                                            <button className={`inline-block p-4 border-b-2 rounded-t-lg ${activeTab === 'Holiday' ? 'border-purple-600 text-purple-600' : 'hover:text-gray-600 hover:border-gray-300'}`} onClick={() => setActiveTab('Holiday')} type="button" role="tab">
                                                Holiday
                                            </button>
                                        </li> */}
                                    </ul>
                                </div>
                                <div id="default-styled-tab-content">
                                    {renderTabContent()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BottomNavigation />
            </div>
        </>
    );
}