import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { Home } from './Home';
import { ViewAllChildrens } from './ViewAllChildrens';
import { Login } from './Login';
import { ProtectedComponent } from './ProtectedComponent';
import { ViewChildrenDetails } from './ViewChildrenDetails';
import { ViewChildrenFee } from './ViewChildrenFee';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={ <ProtectedComponent RedirectComponent={ Home } /> } />
        <Route path="/ViewAllChildrens" element={ <ProtectedComponent RedirectComponent={ ViewAllChildrens } /> } />
        <Route path="/ViewChildrenDetails/:childrenId" element={ <ProtectedComponent RedirectComponent={ ViewChildrenDetails } /> } />
        <Route path="/ViewChildrenFee" element={ <ProtectedComponent RedirectComponent={ ViewChildrenFee } /> } />
        <Route path="/Login" element={ <Login /> } />
      </Routes>
    </Router>
  );
}

export default App;