import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

export function BannerCarousel() {
    return (
        /* <Carousel showArrows={true} showStatus={false} showIndicators={true} infiniteLoop={true} autoPlay={true} interval={5000} stopOnHover={true} transitionTime={500} emulateTouch={true} showThumbs={false}> */
        <Carousel autoPlay interval={3000} showStatus={false} showThumbs={false} infiniteLoop>
            <div>
                <img src="https://iskconrajkot.com/wp-content/uploads/2021/08/1400-X-500-PIXEL-WEBSITE-SLIDER-BANNER-DESIGN.jpg" className="rounded-xl" alt="Banner 1" />
            </div>
            <div>
                <img src="https://bullbitescafe.com/its_admin/img/react-js/logo-2.png" className="rounded-xl" alt="Banner 2" />
            </div>
            <div>
                <img src="https://bullbitescafe.com/its_admin/img/react-js/logo-3.png" className="rounded-xl" alt="Banner 3" />
            </div>
        </Carousel>
    );
}