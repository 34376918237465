import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function ProtectedComponent(Props) {
    const { RedirectComponent } = Props;
    const navigate = useNavigate();

    useEffect( () => {
        if(!localStorage.getItem("loginDetails"))
        {
            navigate("/Login");
        }
    })

    return (
        <>
            <RedirectComponent />
        </>
    )
}