import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API_URL } from './Config';
import { useNavigate } from 'react-router-dom';
import { Header } from './Header';
import { BottomNavigation } from './BottomNavigation';
import { BannerCarousel } from './CarouselComponent';

export function Home() {
    const [totalStudents, setTotalStudents] = useState(0);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const RedirectToViewAllChildrens = () => {
        navigate("/ViewAllChildrens");
    }
    
    const RedirectToWebsite = () => {
        window.open('https://kodexive.com/', '_blank');
    }
    
    const getChildrensQuantity = async () => {
        try {
            const mobileNumber = localStorage.getItem('Mobile');

            const response = await axios.post(`${API_URL}/ParentsAPI.php`, {
                aksi: 'Parents_Childrens',
                MobileNumber: mobileNumber
            });

            if (response.data.success)
            {
                setTotalStudents(response.data.total_students);
            }
        } catch (error) {
            console.error("Error", error);
        }
        finally
        {
            setLoading(false);
        }
    };
    
    useEffect(() => {
        getChildrensQuantity();
        
        const intervalId = setInterval(getChildrensQuantity, 5000);
        return () => clearInterval(intervalId);
    }, []);

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    return (
        <>
            <Header />
            <BottomNavigation />
            <div className="max-w-md mx-auto">
                <div className="container mx-auto mt-20 px-2">
                    <BannerCarousel />
                    <div className="grid grid-cols-2 gap-2 mt-5">
                        <div className="flex flex-col border-2 border-gray-400 rounded-xl py-1 px-2 bg-purple-400 shadow-lg">
                            <img src="/icons/fee-2.png" alt="Students" className="w-16 mb-1" />
                            <p className="font-semibold" style={{ fontFamily: 'Varela Round', fontSize: '15px' }}>Pay Fee</p>
                        </div>
                        <div onClick={ RedirectToWebsite } className="flex flex-col border-2 border-gray-400 rounded-xl py-1 px-2 bg-orange-300 shadow-lg">
                            <img src="/icons/browser.png" alt="Students" className="w-16 mb-1" />
                            <p className="font-semibold" style={{ fontFamily: 'Varela Round', fontSize: '15px' }}>Visit Website</p>
                        </div>
                    </div>
                    <h1 className="text-lg font-bold text-gray-800 mt-5" style={{ fontFamily: 'Varela Round' }}>Explore...</h1>
                    <div className="grid grid-cols-2 gap-2 mt-1">
                        <div onClick={ RedirectToViewAllChildrens } className="flex flex-col items-center justify-center text-center border-2 border-gray-400 rounded-xl py-3 px-2 bg-blue-200 shadow-lg">
                            <img src="/icons/students.png" alt="Students" className="w-16 mb-1" />
                            <p className="font-semibold" style={{ fontFamily: 'Varela Round', fontSize: '15px' }}>My Kids ({ totalStudents })</p>
                        </div>
                        <div className="flex flex-col items-center justify-center text-center border-2 border-gray-400 rounded-xl py-3 px-2 bg-blue-200 shadow-lg">
                            <img src="/icons/attendence.png" alt="Attendance" className="w-16 mb-1" />
                            <p className="font-semibold" style={{ fontFamily: 'Varela Round', fontSize: '15px' }}>Attendance</p>
                        </div>
                        {/* <div className="flex flex-col items-center justify-center text-center border-2 border-gray-400 rounded-xl py-3 px-2 bg-blue-200 shadow-lg">
                            <img src="/icons/homework.png" alt="Homework" className="w-16 mb-1" />
                            <p className="font-semibold" style={{ fontFamily: 'Varela Round', fontSize: '15px' }}>Homework</p>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}